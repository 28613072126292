import React, { useEffect, useState } from 'react'
import { Alert, IconButton, InputAdornment, MenuItem } from '@mui/material'
import { useMarketDiscountService } from '../../service/market-discount-service'
import { AddDiscountRequest, DiscountType } from '../../service/types/discount'
import { useForm } from 'react-hook-form'
import { useDataProvider, useNotify } from 'ra-core'
import { useBoolean } from '../../hooks/use-boolean'
import FormProvider, { RHFDateField, RHFTextField } from '../../components/hook-form'
import { RHFSelect } from '../../components/hook-form/rhf-select'
import Stack from '@mui/material/Stack'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { City, District } from '../../service/types/location'
import DiscountInfo from './discount-info'
import LoadingButton from '@mui/lab/LoadingButton'
import Container from '@mui/material/Container'
import CancelIcon from '@mui/icons-material/Cancel'

type Props = {
    onAdded: VoidFunction
}

function AddDiscountForm({ onAdded }: Props) {
    const marketDiscountService = useMarketDiscountService()
    const loading = useBoolean(false)
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [districts, setDistricts] = useState<District[]>([])
    const [cities, setCities] = useState<City[]>([])

    useEffect(() => {
        dataProvider.getList('districts', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setDistricts(data)
        }).catch(() => {
            notify('İlçe getiriken hata oluştu', { type: 'error' })
        })

        dataProvider.getList('cities', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setCities(data)
        }).catch(() => {
            notify('Şehir getiriken hata oluştu', { type: 'error' })
        })
    }, [])

    const schema = Yup.object().shape({
        discountType: Yup.mixed<DiscountType>()
            .oneOf(Object.values(DiscountType))
            .required('Indirim tipi seçiniz.'),
        discount: Yup.number()
            .required('Indirim miktarı giriniz.')
            .when('discountType', (values: DiscountType[], schema) => {
                return values[0] === DiscountType.amount
                    ? schema.min(1, 'Indirim miktarı 1\'den büyük olmalıdır.').max(3000, 'Indirim miktarı 3000\'den küçük olmalıdır.')
                    : schema.max(100, 'Indirim miktarı yüzde 100\'den küçük olmalıdır.')
            }),
        orderCount: Yup.number().required('Sipariş sayısı giriniz.'),
        startDate: Yup.date().required('Başlangıç tarihi giriniz.'),
        scheduledEndDate: Yup.date()
            .nullable()
            .min(Yup.ref('startDate'), 'Bitiş tarihi başlangıç tarihinden sonra veya aynı olmalıdır.'),
        districtId: Yup.string().nullable(),
        cityId: Yup.string().nullable(),
        enTitle: Yup.string().required('İndirim başlığı giriniz.'),
        trTitle: Yup.string().required('İndirim başlığı giriniz.'),
        minOrderAmount: Yup.number().required('Minimum sipariş tutarı giriniz.').min(1, 'Minimum sipariş tutarı 1\'den büyük olmalıdır.'),
    }).test('city-or-district', 'Şehir veya ilçe seçiniz.', function(value) {
        return !!value.cityId || !!value.districtId
    })

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            discountType: DiscountType.percentage,
            discount: 1,
            orderCount: 1,
            startDate: new Date(),
            scheduledEndDate: null,
            enTitle: '',
            trTitle: '',
            districtId: '',
            cityId: '',
        },
    })

    const {
        reset,
        handleSubmit,
        watch,
        setValue,
    } = methods

    const discountType = watch('discountType')
    const discount = watch('discount')
    const orderCount = watch('orderCount')
    const startDate = watch('startDate')
    const scheduledEndDate = watch('scheduledEndDate')
    const districtId = watch('districtId')
    const cityId = watch('cityId')
    const district = districts.find(d => d.id === districtId)
    const city = cities.find(c => c.id === cityId)
    const minOrderAmount = watch('minOrderAmount')
    const onSubmit = handleSubmit(async (data) => {
        loading.onTrue()
        try {
            const request: AddDiscountRequest = {
                districtId: data.districtId !== '' ? data.districtId : undefined,
                cityId: data.cityId !== '' ? data.cityId : undefined,
                discount: data.discount,
                orderCount: data.orderCount,
                startDate: data.startDate.getTime(),
                scheduledEndDate: data.scheduledEndDate?.getTime(),
                discountType: data.discountType,
                minOrderAmount: data.minOrderAmount,
                title: {
                    english: data.enTitle,
                    turkish: data.trTitle,
                },
            }
            await marketDiscountService.addDiscount(request)
            onAdded()
            notify('Indirim eklendi', { type: 'success' })
            reset()
        } catch (e) {
            notify(`İndirim eklerken hata oluşturdu. ${e.message}`, { type: 'error' })
        } finally {
            loading.onFalse()
        }
    })

    return (
        <Container maxWidth={'md'}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={2} sx={{ mt: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <RHFTextField label="Indirim Başlığı (TR)" name="trTitle"
                                      disabled={loading.value}
                                      helperText={'Indirim başlığını giriniz.'} />
                        <RHFTextField label="Indirim Başlığı (EN)" name="enTitle"
                                      disabled={loading.value}
                                      helperText={'Indirim başlığını giriniz.'} />
                    </Stack>
                    <RHFSelect label="Indirim Tipi" name="discountType"
                               disabled={loading.value}
                               helperText={'Indirim tipini seçiniz.'}>
                        <MenuItem value={DiscountType.percentage}>Yüzde</MenuItem>
                        <MenuItem value={DiscountType.amount}>Tutar</MenuItem>
                    </RHFSelect>
                    <RHFTextField label={discountType === 'PERCENTAGE' ? 'Indirim Miktarı (%)' : 'Indirim Miktarı'}
                                  name="discount"
                                  disabled={loading.value}
                                  helperText={'Indirim miktarı giriniz - TL olarak yada yüzde olarak.'}
                                  type="number" />
                    <RHFTextField label="Kaçıncı Sipariş"
                                  name="orderCount"
                                  disabled={loading.value}
                                  helperText={'Müşterinin kaçıncı siparişinde bu indirimi alacağını giriniz.'}
                                  type="number" />
                    <RHFTextField label="Minimum Sipariş Tutar"
                                  name="minOrderAmount"
                                  disabled={loading.value}
                                  helperText={'Müşterinin bu indirimden yararlanabilmesi için gerekli minimum sipariş tutarını giriniz.'}
                                  type="number" />
                    <RHFSelect label="Şehir/İl" name="cityId"
                               disabled={loading.value || !!districtId}
                               helperText={!districtId ? 'Şehir/İl seçiniz.' : ''}
                               InputProps={{
                                   startAdornment: cityId && (
                                       <InputAdornment position="start">
                                           <IconButton
                                               onClick={() => {
                                                   setValue('cityId', '')
                                               }}
                                               edge="start"
                                           >
                                               <CancelIcon />
                                           </IconButton>
                                       </InputAdornment>
                                   ),
                               }}>
                        {cities.map(city => (
                            <MenuItem key={city.id} value={city.id}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <RHFSelect label="İlçe" name="districtId"
                               disabled={loading.value || !!cityId}
                               helperText={!cityId ? 'İlçe seçiniz.' : ''}
                               InputProps={{
                                   startAdornment: districtId && (
                                       <InputAdornment position="start">
                                           <IconButton
                                               onClick={() => {
                                                   setValue('districtId', '')
                                               }}
                                               edge="start"
                                           >
                                               <CancelIcon />
                                           </IconButton>
                                       </InputAdornment>
                                   ),
                               }}>
                        {districts.map(district => (
                            <MenuItem key={district.id} value={district.id}>
                                {district.name}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <RHFDateField label="Başlangıç Tarihi"
                                  name="startDate"
                                  disabled={loading.value}
                                  minDate={new Date()}
                                  helperText={'İndirimin başlangıç tarihini giriniz.'} />
                    <RHFDateField label="Bitiş Tarihi"
                                  name="scheduledEndDate"
                                  clearable
                                  disabled={loading.value}
                                  minDate={new Date()}
                                  helperText={'İndirimin bitiş tarihini giriniz. Süresiz indirim icin boş bırakın'} />
                    <Alert severity="info">
                        <DiscountInfo discountType={discountType}
                                      minOrderAmount={minOrderAmount}
                                      discount={discount}
                                      orderCount={orderCount}
                                      district={district}
                                      city={city}
                                      startDate={startDate}
                                      scheduledEndDate={scheduledEndDate} />
                    </Alert>
                </Stack>
                <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
                    <LoadingButton loading={loading.value} type="submit" variant="contained" color="primary">Indirim Ekle</LoadingButton>
                </Stack>
            </FormProvider>
        </Container>
    )
}

export default AddDiscountForm