import { Controller, useFormContext } from 'react-hook-form'
import React from 'react'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { IconButton, InputAdornment } from '@mui/material'
import ClearIcon from '@mui/icons-material/Cancel'

type Props = DatePickerProps<any> & {
    name: string
    helperText?: string,
    minDate?: Date,
    clearable?: boolean
}

export default function RHFDateField({ name, helperText, clearable, minDate, ...other }: Props) {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        {...field}
                        value={field.value ? new Date(field.value) : null}
                        format={'dd.MM.yyyy'}
                        minDate={minDate}
                        slotProps={{
                            textField: {
                                helperText: error ? error.message : helperText,
                                error: !!error,
                                sx: {
                                    textAlign: 'center',
                                },
                                fullWidth: true,
                                InputProps: clearable ? {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                onClick={() => {
                                                    field.onChange(null)
                                                }}
                                                edge="start"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                } : undefined,
                            },
                        }}
                        onChange={(newValue) => {
                            field.onChange(newValue)
                        }}
                        {...other}
                    />
                </LocalizationProvider>
            )}
        />
    )
}