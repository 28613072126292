import React, { useEffect, useState } from 'react'
import { useFinanceService } from '../../service/finance-service'
import { TablePaginationCustom, useTable } from '../../components/table'
import { useBoolean } from '../../hooks/use-boolean'
import { useSnackbar } from 'notistack'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHeadCustom from '../../components/table/table-head-custom'
import TableBody from '@mui/material/TableBody'
import LinearProgressView from '../../common/linear-progress-view'
import { MarketDiscountUsage } from '../../service/types/finance'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MarketDiscountUsageRow from './market-discount-row'

const paymentHeadLabel = [
    { id: 'name', label: 'Adı' },
    { id: 'discount', label: 'Toplam İndirim' },
    { id: 'commission', label: 'Toplam Komisyon' },
    { id: 'orders', label: 'Sipariş Sayısı' },
    { id: 'orderTotal', label: 'Toplam Sipariş' },
    { id: 'marketType', label: 'Market Tipi' },
    { id: 'detail', label: 'Aktion' },
]

type MarketDiscountUsageViewProps = {
    start: number;
    end: number;
    onDetail: (marketId: string, title: string) => void;
};

export default function MarketDiscountUsageView({ start, end, onDetail }: MarketDiscountUsageViewProps) {
    const financeService = useFinanceService()
    const table = useTable()
    const loading = useBoolean(true)
    const [payments, setPayments] = useState<MarketDiscountUsage[]>([])
    const { enqueueSnackbar } = useSnackbar()
    const [filteredPayments, setFilteredPayments] = useState<MarketDiscountUsage[]>([])
    const [search, setSearch] = useState('')

    useEffect(() => {
        const fetch = async () => {
            loading.setValue(true)
            await financeService.getMarketDiscountUsages(start, end)
                .then(setPayments)
                .catch(() => {
                    enqueueSnackbar('Online ödemeler yüklenemedi', { variant: 'error' })
                    setPayments([])
                }).finally(loading.onFalse)
        }
        void fetch()
    }, [start, end])

    useEffect(() => {
        if (search === '') {
            setFilteredPayments(payments)
        } else {
            setFilteredPayments(payments.filter(payment => payment.name.toLowerCase().includes(search.toLowerCase())))
        }
    }, [payments, search])

    return <>
        <Box>
            <TextField
                label="Ara"
                value={search}
                margin={'normal'}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
                size="small"
            />
        </Box>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={paymentHeadLabel}
                    rowCount={filteredPayments.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                />

                <TableBody>
                    {loading.value
                        ? <LinearProgressView />
                        : filteredPayments
                            .slice(
                                table.page * table.rowsPerPage,
                                table.page * table.rowsPerPage + table.rowsPerPage,
                            )
                            .map((payment) => <MarketDiscountUsageRow key={payment.id}
                                                                      usage={payment}
                                                                      onDetail={onDetail} />)}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePaginationCustom
            count={filteredPayments.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            labelRowsPerPage="Sayfa başına satır:"
        />
    </>
}