import React from 'react'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useRecordContext } from 'react-admin'
import { useAuthService } from '../../service/auth-service'
import { useNavigate } from 'react-router-dom'
import ShowIcon from '@mui/icons-material/Visibility'
import CustomPopover, { usePopover } from '../../components/custom-popover'

export function BranchRowMenu() {
    const record = useRecordContext()
    const service = useAuthService()
    const navigate = useNavigate()
    const popover = usePopover()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        popover.onOpen(event)
    };

    const showMarket = async () => {
        try {
            const token = await service.marketToken()
            const login = `login?username=${encodeURIComponent(record.username)}&password=${encodeURIComponent(record.password)}&token=${token.token}`
            const location = window.location.hostname === 'localhost' ? 'http://localhost:3000' :
                window.location.hostname.includes('dev-admin') ? 'https://dev-store.nerdekaldi.app' : 'https://market.nerdekaldi.app'
            window.open(`${location}/#/${login}`, '_blank', 'noopener,noreferrer')
        } catch (e) {
            // ignore
        }
        popover.onClose()
    }
    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="branch-row-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <CustomPopover
                id="branch-row-menu"
                keepMounted
                open={popover.open}
                onClose={popover.onClose}
            >
                <MenuItem onClick={showMarket}>
                    <ShowIcon/>
                    Marketi Görüntüle
                </MenuItem>
            </CustomPopover>
        </>
    );
}