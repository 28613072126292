import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Order } from '../../../service/types/user'
import { useOrderService } from '../../../service/order-service'
import OrderTable from '../../orders/order-table'


type DiscountedOrderTableProps = {
    start: number;
    end: number;
    marketId: string;
    onLoading: (loading: boolean) => void;
};

type Query = {
    page: number;
    rowsPerPage: number;
    order?: 'ASC' | 'DESC';
    orderBy?: string;
}

export default function DiscountedOrderTable({ start, end, marketId, onLoading }: DiscountedOrderTableProps) {
    const orderService = useOrderService()
    const [order, setOrders] = useState<Order[]>([])
    const [query, setQuery] = useState<Query>({ page: 0, rowsPerPage: 10 })
    const [totalItems, setTotalItems] = useState(0)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        query.page = 0
        setQuery({ ...query })
    }, [start, end, marketId, query.rowsPerPage])

    useEffect(() => {
        const fetch = async () => {
            await fetchPayments()
        }
        void fetch()
    }, [query.page, query.order, query.orderBy])

    async function fetchPayments() {
        onLoading(true)
        await orderService.getDiscountedOrders(marketId, start, end, query.page, query.rowsPerPage)
            .then((page) => {
                setOrders(page.content)
                setTotalItems(page.totalElements)
            })
            .catch(() => {
                enqueueSnackbar('Siparişler yüklenemedi. Lütfen tekrar deneyin', { variant: 'error' })
                setOrders([])
            })
            .finally(() => {
                onLoading(false)
            })
    }

    return <>
        <OrderTable orders={order} refresh={fetchPayments}
                    onRowsPerPageChange={(rowsPerPage) => {
                        query.rowsPerPage = rowsPerPage
                        setQuery({ ...query })
                    }}
                    onChangePage={(page) => {
                        query.page = page
                        setQuery({ ...query })
                    }}
                    page={query.page}
                    rowsPerPage={query.rowsPerPage}
                    onSort={(payload) => {
                        query.order = payload.order
                        query.orderBy = payload.field
                        setQuery({ ...query })
                    }
                    }
                    total={totalItems} />
    </>
}