import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'
import { AddDiscountRequest, DiscountConfig } from './types/discount'

export const useMarketDiscountService = () => {
    const api = useRestService()
    return new MarketDiscountService(api)
}

class MarketDiscountService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async get(activeOnly?: boolean): Promise<DiscountConfig[]> {
        return await this.api.get(`/discounts/get`,{
            params: { activeOnly }
        }).then(it => it.data)
    }

    public async addDiscount(request: AddDiscountRequest): Promise<DiscountConfig> {
        return await this.api.post(`/discounts/add`, request).then(it => it.data)
    }

    public async terminateDiscount(discountId: string): Promise<void> {
        await this.api.post(`/discounts/terminate`, undefined, { params: { id: discountId } });
    }
}