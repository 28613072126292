export type LoggedUser = {
    name: string,
    username: string,
    token: string,
    authority: string,
}
export type LoginResponse = {
    token: string
    method: VerificationMethod
}

export enum VerificationMethod {
    googleAuth = 'GOOGLE_AUTH',
    email = 'EMAIL',
}

export type UserAddress = {
    id: string,
    title: string,
    address: string,
    createdAt: number,
}
export type OrderItem = {
    product: string,
    quantity: number,
    price: number,
    discount?: number
}

export enum OrderStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    DELIVERED = 'DELIVERED',
    CANCELLED = 'CANCELLED',
    ONWAY = 'ONWAY'
}

export type PaymentType = {
    id: string,
    name: string,
    active: boolean
}

export type Order = {
    id: string,
    items: OrderItem[],
    customer: string,
    address: string,
    status: OrderStatus,
    total: number,
    customerNote?: string,
    suitableTime: number,
    driver?: string,
    pendingTime: number,
    confirmTime?: number,
    deliverTime?: number,
    cancelTime?: number,
    cancelReason?: string,
    finalPrice: number,
    deliveryFee?: number,
    paymentType: PaymentType,
    discount?: {
        title: string,
        discount: number
    }
}