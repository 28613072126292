import React, { useState } from 'react'
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useMarketDiscountService } from '../../service/market-discount-service'
import { DiscountCity, DiscountCondition, DiscountConditionType, DiscountConfig, DiscountDistrict, DiscountType } from '../../service/types/discount'
import Label from 'src/components/label/label'
import { fDateTime } from '../../utils/format-time'
import { fCurrencyTl } from '../../utils/format-number'
import { useNotify } from 'ra-core'
import { useBoolean } from '../../hooks/use-boolean'
import DiscountInfo from './discount-info'

type DiscountTableProps = {
    onTerminated: VoidFunction,
    discounts: DiscountConfig[],
}

function DiscountTable({ discounts, onTerminated }: DiscountTableProps) {
    const marketDiscountService = useMarketDiscountService()
    const confirmOpen = useBoolean(false)
    const [selectedDiscount, setSelectedDiscount] = useState<DiscountConfig | null>(null)
    const [infoOpen, setInfoOpen] = useState(false)
    const notify = useNotify()

    const handleTerminateDiscount = async (discountId: string) => {
        try {
            await marketDiscountService.terminateDiscount(discountId)
            notify('Indirim sonlandırıldı', { type: 'info' })
            onTerminated()
        } catch (error) {
            notify('Indirim sonlandırılamadı. Lütfen tekar deneyin', { type: 'error' })
        }
    }

    const handleInfoOpen = (discount: DiscountConfig) => {
        setSelectedDiscount(discount)
        setInfoOpen(true)
    }

    const handleInfoClose = () => {
        setInfoOpen(false)
        setSelectedDiscount(null)
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Başlık</TableCell>
                        <TableCell>İl</TableCell>
                        <TableCell>Ilçe</TableCell>
                        <TableCell>Kaçıncı Sipariş&apos;ta</TableCell>
                        <TableCell>Minimum Sipariş Tutarı</TableCell>
                        <TableCell>İndirim Tip</TableCell>
                        <TableCell>İndirim</TableCell>
                        <TableCell>İndirim Durumu</TableCell>
                        <TableCell>Başlangıç Tarih</TableCell>
                        <TableCell>Bitiş Tarih</TableCell>
                        <TableCell>Aktion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {discounts.map(discount => (
                        <TableRow key={discount.id}>
                            <TableCell>
                                <IconButton onClick={() => {
                                    handleInfoOpen(discount)
                                }}>
                                    <InfoIcon />
                                </IconButton>
                                {discount.title.turkish}
                            </TableCell>
                            <TableCell>
                                <ConditionView conditions={discount.conditions} type={DiscountConditionType.city} />
                            </TableCell>
                            <TableCell>
                                <ConditionView conditions={discount.conditions} type={DiscountConditionType.district} />
                            </TableCell>
                            <TableCell>
                                <ConditionView conditions={discount.conditions} type={DiscountConditionType.orderCount} />
                            </TableCell>
                            <TableCell>
                                <ConditionView conditions={discount.conditions} type={DiscountConditionType.minOrderAmount} />
                            </TableCell>
                            <TableCell><DiscountTypeLabel discountType={discount.discountType} /></TableCell>
                            <TableCell>
                                <DiscountValueDisplay discountType={discount.discountType} discount={discount.discount} />
                            </TableCell>
                            <TableCell><DiscountStatus discount={discount} /></TableCell>
                            <TableCell>{fDateTime(discount.startDate)}</TableCell>
                            <TableCell>{discount.scheduledEndDate ? fDateTime(discount.scheduledEndDate) : 'Sonsuz'}</TableCell>
                            <TableCell>
                                {!discount.endDate && <Button color={'error'}
                                                              onClick={() => {
                                                                  setSelectedDiscount(discount)
                                                                  confirmOpen.onTrue()
                                                              }}
                                                              variant={'contained'}>Indirim Sonlandır</Button>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {selectedDiscount && <Dialog open={confirmOpen.value} onClose={confirmOpen.onFalse}>
                <DialogTitle>Indirim Sonlandır</DialogTitle>
                <DialogContent>Indirimi sonlandırmak istediğinize emin misiniz?</DialogContent>
                <DialogActions>
                    <Button onClick={confirmOpen.onFalse}>Iptal</Button>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            void handleTerminateDiscount(selectedDiscount.id)
                            setSelectedDiscount(null)
                            confirmOpen.onFalse()
                        }}>Evet</Button>
                </DialogActions>
            </Dialog>
            }
            {selectedDiscount && <Dialog open={infoOpen} onClose={handleInfoClose}>
                <DialogTitle>Indirim Bilgisi</DialogTitle>
                <DialogContent>
                    <DiscountInfo
                        discountType={selectedDiscount.discountType}
                        discount={selectedDiscount.discount}
                        minOrderAmount={selectedDiscount.conditions.find(c => c.type === DiscountConditionType.minOrderAmount)?.value as number}
                        orderCount={selectedDiscount.conditions.find(c => c.type === DiscountConditionType.orderCount)?.value as number}
                        district={selectedDiscount.conditions.find(c => c.type === DiscountConditionType.district)?.value as DiscountDistrict}
                        city={selectedDiscount.conditions.find(c => c.type === DiscountConditionType.city)?.value as DiscountCity}
                        startDate={new Date(selectedDiscount.startDate)}
                        scheduledEndDate={selectedDiscount.scheduledEndDate ? new Date(selectedDiscount.scheduledEndDate) : null}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInfoClose}>Kapat</Button>
                </DialogActions>
            </Dialog>
            }
        </TableContainer>
    )
}

const ConditionView = ({ conditions, type }: { conditions: DiscountCondition[], type: DiscountConditionType }) => {
    const condition = conditions.find(it => it.type === type)
    if (!condition) {
        return <></>
    }
    return <DiscountConditionLabel condition={condition} />
}

export const DiscountStatus = ({ discount }: { discount: DiscountConfig }) => {
    return <Label color={discount.endDate === null ? 'success' : 'error'}>
        {discount.endDate === null ? 'Aktif' : `${fDateTime(discount.endDate)} - Sonlandırıldı`}
    </Label>
}

export const DiscountValueDisplay = ({ discountType, discount }: { discountType: DiscountType, discount: number }) => {
    return <span>
        {discountType === DiscountType.percentage ? discount + '%' : fCurrencyTl(discount)}
    </span>
}

export const DiscountTypeLabel = ({ discountType }: { discountType: DiscountType }) => {
    return <Label color={discountType === DiscountType.percentage ? 'info' : 'primary'}>
        {discountType === DiscountType.percentage ? 'Yüzde' : 'Tutar'}
    </Label>
}

export const DiscountConditionLabel = ({ condition }: { condition: DiscountCondition }) => {
    if (condition.type === DiscountConditionType.orderCount) {
        return <OrderCountDisplay orderCount={condition.value as number} />
    }
    if (condition.type === DiscountConditionType.district) {
        return <DistrictDisplay district={condition.value as DiscountDistrict} />
    }
    if (condition.type === DiscountConditionType.minOrderAmount) {
        return <MinOrderAmountDisplay minOrderAmount={condition.value as number} />
    }
    if (condition.type === DiscountConditionType.city) {
        return <CityDisplay city={condition.value as DiscountCity} />
    }
    return <></>
}

export const DistrictDisplay = ({ district }: { district: DiscountDistrict }) => {
    return <Label color={'primary'}>
        {district.name}
    </Label>
}
export const CityDisplay = ({ city }: { city: DiscountCity }) => {
    return <Label color={'info'}>
        {city.name}
    </Label>
}

export const MinOrderAmountDisplay = ({ minOrderAmount }: { minOrderAmount: number }) => {
    return <span color={'primary'}>
        {fCurrencyTl(minOrderAmount)}
    </span>
}

const OrderCountDisplay = ({ orderCount }: { orderCount: number }) => {
    return <span color={'primary'}>
        {orderCount}
    </span>
}

export default DiscountTable