import React from 'react'
import { DiscountType } from '../../service/types/discount'
import { fDate } from '../../utils/format-time'
import { City, District } from '../../service/types/location'

type DiscountInfoProps = {
    discountType: DiscountType,
    discount: number,
    minOrderAmount: number,
    orderCount: number,
    district?: District,
    city?: City,
    startDate?: Date,
    scheduledEndDate?: Date | null,
}

function getOrderCountDescription(orderCount: number): string {
    const descriptions = [
        'Birinci', 'İkinci', 'Üçüncü', 'Dördüncü', 'Beşinci',
        'Altıncı', 'Yedinci', 'Sekizinci', 'Dokuzuncu', 'Onuncu',
    ]
    return descriptions[orderCount - 1] ?? `${orderCount}.`
}

function getDateDescription(date?: Date): string {
    return fDate(date)
}

function DiscountInfo({
                          discountType,
                          discount,
                          orderCount,
                          district,
                          startDate,
                          minOrderAmount,
                          city,
                          scheduledEndDate,
                      }: DiscountInfoProps) {
    return (
        <ul>
            {startDate && (
                <li>
                    Bu indirim <b>{getDateDescription(startDate)}</b> tarihinde saat 00:00 dan başlayacaktır.
                </li>
            )}
            {scheduledEndDate && (
                <li>
                    Bu indirim <b>{getDateDescription(scheduledEndDate)}</b> saat 23:59 da sona erecektir.
                </li>
            )}
            {district && (
                <li>
                    Bu indirim sadece <b>{district.name}</b> ilçesindeki müşterilere uygulanıcaktır.
                </li>
            )}
            {city && (
                <li>
                    Bu indirim sadece <b>{city.name}</b> şehir/il indeki müşterilere uygulanıcaktır.
                </li>
            )}
            {discountType === DiscountType.percentage ? (
                <li>
                    Bu indirim müşterinin <b>{getOrderCountDescription(orderCount)}</b> siparişinde <b>%{discount}</b> olarak uygulanacaktır.
                </li>
            ) : (
                <li>
                    Bu indirim müşterinin <b>{getOrderCountDescription(orderCount)}</b> siparişinde <b>{discount}</b> TL olarak uygulanacaktır.
                </li>
            )}

            {minOrderAmount && (
                <li>
                    Bu indirimden yararlanabilmek için müşterinin sipariş tutarının en az <b>{minOrderAmount}</b> TL olması gerekmektedir.
                </li>)
            }
        </ul>
    )
}

export default DiscountInfo