import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useBoolean } from '../../../hooks/use-boolean'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import DiscountedOrderTable from './discounted-order-table'

type MarketDiscountedOrdersDialogProps = {
    open: boolean;
    onClose: () => void;
    start: number;
    end: number;
    marketId: string;
    title: string;
};

const MarketDiscountedOrdersDialog = ({ open, onClose, start, end, marketId, title }: MarketDiscountedOrdersDialogProps) => {
    const loading = useBoolean()

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <span>{title} - İndirimleri Siparişler</span>
                    {loading.value && <CircularProgress size={24} />}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DiscountedOrderTable start={start}
                                      onLoading={loading.setValue}
                                      end={end} marketId={marketId} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Kapat</Button>
            </DialogActions>
        </Dialog>
    )
}

export default MarketDiscountedOrdersDialog