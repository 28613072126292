import React, { useState } from 'react'
import { Box, Card, Tab, Tabs } from '@mui/material'
import MarketCommissionsView from './market-commission-table'
import { DateRangeInput } from '../../common/date/date-range-input'
import Icon from '@mui/icons-material/AccountBalanceWalletRounded'
import OnlinePaymentsView from './online-payments-table'
import IyzicoPaymentsDialog from './detail/market-payments-dialog'
import MarketDiscountUsageView from './market-discount-usage'
import MarketDiscountedOrdersDialog from './detail/market-discounted-orders-dialog'

export const FinanceIcon = Icon

type DateRange = { start: number; end: number; };
const sevenDays = 7 * 24 * 60 * 60 * 1000

function FinanceView() {
    const [value, setValue] = useState(0)
    const [paymentMarketId, setPaymentMarketId] = useState<string>('')
    const [paymentMarketName, setPaymentMarketName] = useState<string>('')
    const [discountedMarketId, setDiscountedMarketId] = useState<string>('')
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
        start: new Date().getTime() - sevenDays,
        end: new Date().getTime(),
    })

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const handleDateRangeChange = (range: DateRange) => {
        setSelectedDateRange(range)
    }

    return (
        <>
            <Card sx={{ width: '100%', overflowY: 'auto' }}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="finans sekmesi">
                        <Tab label="Komisyonlar" />
                        <Tab label="Iyzico Ödemeler" />
                        <Tab label="İndirim Kullanımları" />
                    </Tabs>
                    <DateRangeInput onChange={handleDateRangeChange} value={selectedDateRange} insideFilter={false}
                                    style={{ width: '250px', marginRight: '10px' }} name={'time'} size={'small'} />
                </Box>
                {value === 0 &&
                    <Box p={3}> <MarketCommissionsView start={selectedDateRange.start} end={selectedDateRange.end} />
                    </Box>}
                {value === 1 &&
                    <Box p={3}><OnlinePaymentsView start={selectedDateRange.start} onDetail={(id, name) => {
                        setPaymentMarketName(name)
                        setPaymentMarketId(id)
                    }} end={selectedDateRange.end} />
                    </Box>}
                {value === 2 &&
                    <Box p={3}><MarketDiscountUsageView start={selectedDateRange.start} onDetail={(id, name) => {
                        setDiscountedMarketId(id)
                        setPaymentMarketName(name)
                    }} end={selectedDateRange.end} />
                    </Box>}

            </Card>
            {paymentMarketId && <IyzicoPaymentsDialog open={paymentMarketId.length > 0}
                                                      title={paymentMarketName}
                                                      onClose={() => {
                                                          setPaymentMarketId('')
                                                          setPaymentMarketName('')
                                                      }} start={selectedDateRange.start} end={selectedDateRange.end}
                                                      marketId={paymentMarketId} />}
            {
                discountedMarketId &&
                <MarketDiscountedOrdersDialog open={discountedMarketId.length > 0}
                                              title={paymentMarketName}
                                              onClose={() => {
                                                  setDiscountedMarketId('')
                                                  setPaymentMarketName('')
                                              }} start={selectedDateRange.start} end={selectedDateRange.end}
                                              marketId={discountedMarketId} />
            }
        </>
    )
}

export default FinanceView