import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { Breadcrumbs, Link } from '@mui/material'
import React from 'react'
import { getBreadMatch } from './paths'
import { useTranslate } from 'react-admin'
import SvgIcon from '@mui/material/SvgIcon'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded'
import { useGlobalState } from './global-context'
import Iconify from '../../components/iconify'

const useStyles = makeStyles((theme) => ({
    root: {
        // @ts-ignore
        padding: 5,
        height: '34px',
        marginLeft: '10px',
        borderRadius: '5px',
        minWidth: '150px',
        display: 'table-cell',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '24px',
    },
    text: {
        alignSelf: 'center',
        marginTop: '3px',
    },
    active: {
        // @ts-ignore
        color: theme.palette.text.primary,
    },
    icon: {
        marginRight: '5px',
    },
    inactive: {
        // @ts-ignore
        color: theme.palette.text.secondary,
    },
    link: {
        textDecoration: 'none',
    },
}))

export function BreadMe() {
    const location = useLocation()
    const classes = useStyles()
    const translate = useTranslate()
    const { state } = useGlobalState()
    const path = location.pathname
    const paths = path.split('/').filter((x) => x)

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="Breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {paths.length > 0 ? (
                    paths.map((value, index) => {
                        const last = index === paths.length - 1
                        const to = `/${paths.slice(0, index + 1).join('/')}`
                        const root = getBreadMatch(value, last, state.displays)
                        return last ? (
                            <div className={classes.container} key={to}>
                                {root.icon && (
                                    <SvgIcon
                                        component={root.icon}
                                        fontSize={'small'}
                                        className={classNames(classes.icon, classes.active)}
                                    />
                                )}
                                <span className={classNames(classes.active, classes.text)}>
                                    {root.translate ? translate(root.title) : root.title}
                                </span>
                            </div>
                        ) : (
                            <Link color="inherit" component={RouterLink} to={to} key={to} className={classes.link}>
                                <div className={classes.container}>
                                    {root.icon && (
                                        <SvgIcon
                                            component={root.icon}
                                            fontSize={'small'}
                                            className={classNames(classes.icon, classes.inactive)}
                                        />
                                    )}
                                    <span className={classNames(classes.inactive, classes.text)}>
                                        {root.translate ? translate(root.title) : root.title}
                                    </span>
                                </div>
                            </Link>
                        )
                    })
                ) : (
                    <div className={classes.container} key={'dashboard'}>
                        <Iconify icon={'iconamoon:discount-fill'} className={classNames(classes.icon, classes.active)} />
                        <span className={classNames(classes.active, classes.text)}>
                            Indirimler
                        </span>
                    </div>
                )}
            </Breadcrumbs>
        </div>
    )
}
