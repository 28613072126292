import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'
import { Page } from './types/common'
import { Order } from './types/user'

export const useOrderService = () => new OrderService(useRestService())

class OrderService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async getDiscountedOrders(id: string, start: number, end: number, page: number, size: number): Promise<Page<Order>> {
        return await this.api.get(`/orders/market/${id}/discounted-orders?start=${start}&end=${end}&page=${page}&size=${size}`).then(it => it.data)
    }
}