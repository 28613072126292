import React, { useEffect, useState } from 'react'
import DiscountTable from './discount-table'
import AddDiscountDialog from './add-discount-dialog'
import Box from '@mui/material/Box'
import { Button, Tabs, Tab, Typography, TabProps } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { useBoolean } from '../../hooks/use-boolean'
import { DiscountConfig } from '../../service/types/discount'
import { useMarketDiscountService } from '../../service/market-discount-service'
import Label from 'src/components/label/label'
import { LabelColor } from '../../components/label'

function TabLabel({ icon, label, count, color, labelColor }: TabProps & { count: number, color: string, labelColor: LabelColor }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {icon}
            <Typography variant={'body2'} sx={{ marginLeft: 1, color, fontWeight: 'bold' }}>{label}</Typography>
            <Label color={labelColor} sx={{ marginLeft: 1 }}>{count}</Label>
        </Box>
    )
}

export function MarketDiscountsView() {
    const marketDiscountService = useMarketDiscountService()
    const [refresh, setRefresh] = React.useState(false)
    const addOpen = useBoolean(false)
    const [activeTab, setActiveTab] = useState(0)
    const [allDiscounts, setAllDiscounts] = useState<DiscountConfig[]>([])
    const [filteredDiscounts, setFilteredDiscounts] = useState<DiscountConfig[]>([])

    useEffect(() => {
        const fetchDiscounts = async () => {
            try {
                const data = await marketDiscountService.get()
                setAllDiscounts(data)
                setFilteredDiscounts(data)
            } catch (error) {
                console.error('Error fetching discounts:', error)
            }
        }
        void fetchDiscounts()
    }, [refresh])

    useEffect(() => {
        let filteredData = allDiscounts
        if (activeTab === 1) {
            filteredData = allDiscounts.filter(discount => discount.endDate === null)
        } else if (activeTab === 2) {
            filteredData = allDiscounts.filter(discount => discount.endDate !== null)
        }
        setFilteredDiscounts(filteredData)
    }, [activeTab, allDiscounts])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue)
    }

    const allCount = allDiscounts.length
    const activeCount = allDiscounts.filter(discount => discount.endDate === null).length
    const endedCount = allDiscounts.filter(discount => discount.endDate !== null).length

    return <>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2,
            marginBottom: 2,
        }}>
            <Box sx={{ flexGrow: 1 }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: activeTab === 0 ? 'info.main' : activeTab === 1 ? 'success.main' : 'error.main',
                        },
                    }}
                >
                    <Tab label={<TabLabel icon={<AllInclusiveIcon />} label="Hepsi" count={allCount} color="info.main"
                                          labelColor={'info'} />} />
                    <Tab label={<TabLabel icon={<CheckCircleIcon />} label="Aktif" count={activeCount} color="success.main"
                                          labelColor={'success'} />} />
                    <Tab label={<TabLabel icon={<CancelIcon />} label="Sonlandırmiş" count={endedCount} color="error.main"
                                          labelColor={'error'} />} />
                </Tabs>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}>
                <Button variant="contained"
                        color="primary"
                        onClick={addOpen.onTrue}>
                    <AddIcon />
                    Indirim Ekle
                </Button>
            </Box>
        </Box>
        <AddDiscountDialog
            open={addOpen.value}
            handleClose={addOpen.onFalse}
            onAdded={() => {
                setRefresh(!refresh)
            }} />
        <DiscountTable discounts={filteredDiscounts}
                       onTerminated={() => {
                           setRefresh(!refresh)
                       }
                       } />
    </>
}