import React from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import { MarketDiscountUsage } from 'src/service/types/finance'
import { MarketTypeLabel } from './market-commission-row'
import Iconify from 'src/components/iconify'
import { fCurrencyTl } from '../../utils/format-number'

type MarketDiscountRowProps = {
    usage: MarketDiscountUsage;
    onDetail: (marketId: string, title: string) => void;
};

const MarketDiscountUsageRow = ({ usage, onDetail }: MarketDiscountRowProps) => {
    return (
        <TableRow>
            <TableCell>{usage.name}</TableCell>
            <TableCell>{fCurrencyTl(usage.discount)}</TableCell>
            <TableCell>{fCurrencyTl(usage.commission)}</TableCell>
            <TableCell>{usage.ordersCount}</TableCell>
            <TableCell>{fCurrencyTl(usage.orderTotal)}</TableCell>
            <TableCell><MarketTypeLabel marketType={usage.marketType} /></TableCell>
            <TableCell><Button variant={'contained'}
                               onClick={() => {
                                   onDetail(usage.id, usage.name)
                               }}
                               color={'primary'} size={'small'} startIcon={<Iconify
                icon={'solar:info-circle-bold-duotone'} />}>Detay</Button></TableCell>
        </TableRow>
    )
}

export default MarketDiscountUsageRow