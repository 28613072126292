import React from 'react'
import { Button } from '@mui/material'
import { useRecordContext } from 'react-admin'
import { useAuthService } from '../service/auth-service'
import { useNavigate } from 'react-router-dom'

export const ShowMarketButton = () => {
    const record = useRecordContext()
    const service = useAuthService()
    const onClick = async () => {
        try {
            const token = await service.marketToken()
            const login = `login?username=${encodeURIComponent(record.username)}&password=${encodeURIComponent(record.password)}&token=${token.token}`
            const location = window.location.hostname === 'localhost' ? 'http://localhost:3000' :
                window.location.hostname.includes('dev-admin') ? 'https://dev-store.nerdekaldi.app' : 'https://market.nerdekaldi.app'
            window.open(`${location}/#/${login}`, '_blank', 'noopener,noreferrer')
        } catch (e) {
            // ignore
        }

    }
    return <Button variant={'contained'}
                   color={'primary'} onClick={onClick}>Marketi Görüntüle</Button>
}

export const ShowMarketDiscountButton = () => {
    const record = useRecordContext()
    const navigate = useNavigate()
    const onClick = async () => {
        navigate(`/market-discounts/${record.id}`)
    }
    return <Button variant={'contained'}
                   color={'primary'} onClick={onClick}>Market Indirimler</Button>
}