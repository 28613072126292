import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AddDiscountForm from './add-discount-form'
import Typography from '@mui/material/Typography'


type AddDiscountDialogProps = {
    onAdded: () => void,
    handleClose: () => void,
    open: boolean
}

function AddDiscountDialog({ onAdded, open, handleClose }: AddDiscountDialogProps) {

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Typography variant="h6">Indirim Ekle</Typography>
                    <Typography variant="body2">Lütfen indirim eklemek için aşağıdaki formu doldurunuz. Seçtiğiniz ilçede aynı sipariş miktarına sahip aktif bir indirim varsa, otomatik olarak sonlandırılacaktır.</Typography>
                </DialogTitle>
                <DialogContent >
                    <AddDiscountForm onAdded={() => {
                        onAdded()
                        handleClose()
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Iptal
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddDiscountDialog