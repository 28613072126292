import { Localized } from './common'

export type AddDiscountRequest = {
    districtId?: string | null;
    cityId?: string | null;
    discount: number;
    minOrderAmount: number;
    orderCount: number;
    startDate: number;
    title: Localized;
    scheduledEndDate?: number | null;
    discountType: DiscountType;
};

export type DiscountConfig = {
    id: string;
    marketId: string;
    discount: number;
    conditions: DiscountCondition[];
    startDate: number;
    endDate?: number;
    scheduledEndDate?: number;
    title: Localized,
    discountType: DiscountType;
};

export type DiscountCondition = {
    type: DiscountConditionType;
    value: string | number | DiscountDistrict | DiscountCity;
};

export type DiscountDistrict = {
    id: string;
    name: string;
}
export type DiscountCity = {
    id: string;
    name: string;
}

export enum DiscountType {
    percentage = 'PERCENTAGE',
    amount = 'AMOUNT',
}

export enum DiscountConditionType {
    orderCount = 'ORDER_COUNT',
    district = 'ORDER_DISTRICT',
    minOrderAmount = 'MIN_ORDER_AMOUNT',
    city = 'ORDER_CITY',
}