import { AxiosInstance } from 'axios'
import { useRestService } from './base-service'
import { IyzicoPayment, IyzicoPaymentDetail, MarketCommission, MarketDiscountUsage } from './types/finance'
import { Page } from './types/common'

export const useFinanceService = () => new FinanceService(useRestService())

class FinanceService {
    private readonly api: AxiosInstance

    constructor(api: AxiosInstance) {
        this.api = api
    }

    public async getMarketCommissions(start: number, end: number): Promise<MarketCommission[]> {
        return await this.api.get(`/finance/commissions?start=${start}&end=${end}`).then(it => it.data)
    }

    public async getMarketDiscountUsages(start: number, end: number): Promise<MarketDiscountUsage[]> {
        return await this.api.get(`/finance/discount-usage?start=${start}&end=${end}`).then(it => it.data)
    }

    public async getIyzicoPayments(start: number, end: number): Promise<IyzicoPayment[]> {
        return await this.api.get(`/finance/online-payments?start=${start}&end=${end}`).then(it => it.data)
    }

    public async getMarketIyzicoPayments(id: string, start: number, end: number, page: number, size: number): Promise<Page<IyzicoPaymentDetail>> {
        return await this.api.get(`/finance/market/${id}/online-payments?start=${start}&end=${end}&page=${page}&size=${size}`).then(it => it.data)
    }
}